<!--
* @Description:帮助中心问题页
* @Author: 张小兵
* @Date: 2020-07-24 10:57:40
-->
<template>
  <div class="main-problem">
    <div class="left">
      <div class="left-title">
        日志目录
      </div>
      <div class="inner-cent">
        <div class="left-loading" v-en-loading="isLeftLoading">
          <div v-if="problemList.length">
            <div v-for="(item, index) in problemList" class="card-list" :key="index">
              <div :class="classObject(item)" @click.stop="itemClick(item)">{{ item.name }}</div>
              <div class="help-left-item-block" v-if="item.isShowChidren && item.nodes != null && item.nodes.length > 0">
                <div
                  v-for="(lableItem, index1) in item.nodes"
                  @click.stop="itemClick(item, lableItem)"
                  :class="{ 'help-left-item-name': id != lableItem.id, 'help-left-item-name-click': id === lableItem.id }"
                  :key="index + '-' + index1"
                >
                  {{ lableItem.name }}
                </div>
              </div>
            </div>
          </div>
          <en-result type="NoData" v-else></en-result>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-title">{{ rightTitleName }}</div>
      <div class="right-loading" v-en-loading="isRightLoading">
        <div class="right-content" id="right-content" v-html="rightContent" v-if="rightContent"></div>
        <en-result type="NoData" v-else></en-result>
      </div>
    </div>
    <!-- <el-dialog

      :visible.sync="dialogVisible"
      width="80%"
     >
      <img :src="fdimg" style="width:100%;">
      <span slot="footer" class="dialog-footer">

        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->
    <el-image
    class="mypreviewImg"
    v-show="false"
    style="width: 1px; height: 1px"
    :src="fdimg"
    :preview-src-list="[fdimg]">
  </el-image>
  </div>
</template>

<script>
import { request } from "en-js";
import { helpService } from "@/api/help/index";

export default {
  data() {
    return {
      dialogVisible: false,
      fdimg: "",
      problemList: [], // 问题列表数据
      rightTitleName: "", // 右边标题名字
      upId: "", // 第一级选中id
      id: "", // 第二级选中id
      rightContent: null, // 右边显示内容
      isLeftLoading: false, // 左边是否正在请求数据
      isRightLoading: false // 右边是否正在加载内容
    };
  },
  mounted() {
    this.queryHelpDoc();
  },
  methods: {
    itemClick(item, lableItem) {
      if (lableItem != null) {
        this.rightTitleName = lableItem.name;
        this.id = lableItem.id;
        this.isRightLoading = true;
        this.queryHelpDocInfo(lableItem.id);
      } else {
        // 先还原所有记录状态，再来设置当前点击的记录展开状态
        this.problemList.forEach((element) => {
          element.isShowChidren = false;
        });
        item.isShowChidren = true;
        this.rightTitleName = item.name;
        this.upId = item.id;
        this.isRightLoading = true;
        this.queryHelpDocInfo(item.id);
      }
    },
    @request(true, "isLeftLoading", {
      subTitle: "数据加载中"
    })
    async queryHelpDoc() {
      // 获取问题菜单数据
      const usinfo = localStorage.getItem("USERINFO");
      const info = JSON.parse(usinfo);
      const rspData = await helpService.queryHelpDoc({
        type: "3",
        tenantId: info.tenantId
      });
      this.problemList = rspData || [];
      this.isLeftLoading = false;
      if (this.problemList.length > 0) {
        // 有数据，初始化
        this.upId = this.problemList[0].id;
        this.rightTitleName = this.problemList[0].name;
        this.problemList[0].isShowChidren = true;
        if (this.$route.query.id) {
          this.id = this.$route.query.id;
          this.upId = this.$route.query.id;
          this.queryHelpDocInfo(this.$route.query.id);
        } else {
          this.queryHelpDocInfo(this.problemList[0].id);
        }
        // this.queryHelpDocInfo(this.problemList[0].id);
        this.isRightLoading = true;
      }
    },
    @request(true, "isRightLoading", {
      subTitle: "数据加载中"
    })
    async queryHelpDocInfo(idValue) {
      // 获取右边的指定的问题文档内容
      const usinfo = localStorage.getItem("USERINFO");
      const info = JSON.parse(usinfo);
      const rspData = await helpService.queryHelpDocInfo({
        id: idValue,
        tenantId: info.tenantId
      });
      if (rspData != null) {
        this.rightContent = rspData.content;
        this.$nextTick().then(() => {
          // 获取div元素
        const div = document.getElementById("right-content");

        // 获取div内的所有img标签
        const images = div.getElementsByTagName("img");
        const that = this;
        // 为每个img标签添加点击事件
        for (let i = 0; i < images.length; i++) {
            images[i].addEventListener("click", function() {
                console.log(this.getAttribute("src"));
                that.fdimg = this.getAttribute("src");
                that.dialogVisible = true;
                document.querySelector(".mypreviewImg .el-image__preview").click();
            });
        }
        });
      }
      this.isRightLoading = false;
    },
    isHasChild(item) {
      // 判断是否有子项
      if (item.nodes != null && item.nodes.length > 0) {
        return true;
      }
      return false;
    },
    classObject(item) {
      // 多样式切换函数
      return {
        "help-left-name": true,
        "help-left-name-click": this.isHasChild(item) && this.upId === item.id,
        "help-left-name-noChild": !this.isHasChild(item) && this.upId === item.id
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.main-problem {
  width: 100%;
  height: 100%;
  // height:  calc(100% - 50px);
  display: flex;
  .left {
    width: 320px;
    margin-top: 7px;
    // margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 4px;
    background-color: white;
    .left-title {
      height: 50px;
      font-weight: 600;
      align-items: center;
      padding-left: 20px;
      display: flex;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid #e8ecf2;
    }
    .inner-cent {
      overflow: auto;
      height: calc(100% - 50px);
      .left-loading {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
      }
      .card-list {
        border: 1px solid #e8ecf2;
        border-radius: 4px;
        width: calc(100% - 40px);
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
      }
      .help-left-name {
        width: 100%;
        height: 36px;
        padding-left: 15px;
        align-items: center;
        font-size: 12px;
        color: #636c78;
        display: flex;
        cursor: pointer;
      }
      .help-left-name-click {
        width: 100%;
        background-color: #f5f8fc;
        height: 36px;
        padding-left: 15px;
        align-items: center;
        border-radius: 4px 4px 0 0;
        font-size: 12px;
        color: #3e90fe;
        display: flex;
      }
      .help-left-name-noChild {
        width: 100%;
        background-color: #f5f8fc;
        height: 36px;
        border-radius: 4px;
        padding-left: 21px;
        align-items: center;
        font-size: 12px;
        color: #3e90fe;
        display: flex;
      }

      .help-left-item-block {
        width: 100%;
        .help-left-item-name {
          height: 36px;
          padding-left: 21px;
          align-items: center;
          font-size: 12px;
          color: #636c78;
          display: flex;
        }
        .help-left-item-name-click {
          height: 36px;
          padding-left: 21px;
          align-items: center;
          background-color: #f5f8fc;
          color: #3e90fe;
          font-size: 12px;
          display: flex;
        }
      }
    }
  }
  .right {
    width: 100%;
    height: 100%;
    margin: 5px 0;
    background-color: white;

    border-radius: 5px;
    .right-loading {
      height: calc(100% - 50px);
      overflow: hidden;
      overflow-y: auto;
    }
    .right-title {
      height: 50px;
      align-items: center;
      padding-left: 20px;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      color: #333;
      border-bottom: 1px solid #e8ecf2;
    }
    .right-content {
      margin-left: 20px;
      margin-right: 20px;
      word-break: break-all; //加这句英文才会换行
      font-size: 14px;
    }
  }
}
</style>
