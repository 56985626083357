var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-help" },
    [
      _c("div", { staticClass: "left" }, [
        _vm._m(0),
        _c(
          "div",
          {
            directives: [
              {
                name: "en-loading",
                rawName: "v-en-loading",
                value: _vm.isLeftLoading,
                expression: "isLeftLoading",
              },
            ],
            staticClass: "side-main",
          },
          [
            _vm.helpList.length
              ? _c(
                  "div",
                  _vm._l(_vm.helpList, function (item, index) {
                    return _c("div", { key: index, staticClass: "lists" }, [
                      _c(
                        "div",
                        {
                          class: _vm.classObject(item),
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.itemClick(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      item.isShowChidren &&
                      item.nodes != null &&
                      item.nodes.length > 0
                        ? _c(
                            "div",
                            { staticClass: "item-lists" },
                            _vm._l(item.nodes, function (lableItem, index1) {
                              return _c(
                                "div",
                                {
                                  key: index + "-" + index1,
                                  class: {
                                    names: _vm.id != lableItem.id,
                                    "names-click": _vm.id === lableItem.id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.itemClick(item, lableItem)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(lableItem.name) + " ")]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              : _c("en-result", { attrs: { type: "NoData" } }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "main-title" }, [
          _vm._v(_vm._s(_vm.rightTitleName)),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "en-loading",
                rawName: "v-en-loading",
                value: _vm.isRightLoading,
                expression: "isRightLoading",
              },
            ],
            staticClass: "right-loading",
          },
          [
            _vm.rightContent
              ? _c("div", {
                  staticClass: "right-content",
                  attrs: { id: "right-content" },
                  domProps: { innerHTML: _vm._s(_vm.rightContent) },
                })
              : _c("en-result", { attrs: { type: "NoData" } }),
          ],
          1
        ),
      ]),
      _c("el-image", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        staticClass: "mypreviewImg",
        staticStyle: { width: "1px", height: "1px" },
        attrs: { src: _vm.fdimg, "preview-src-list": [_vm.fdimg] },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-title" }, [
      _c("span", [_vm._v("文档目录")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }